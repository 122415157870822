import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "condectur-section" }
const _hoisted_3 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderHjpTable = _resolveComponent("HeaderHjpTable")!
  const _component_HjpTabView = _resolveComponent("HjpTabView")!
  const _component_ActiveSchedule = _resolveComponent("ActiveSchedule")!
  const _component_HistorySchedule = _resolveComponent("HistorySchedule")!
  const _component_Card = _resolveComponent("Card")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Card, { class: "card-no-shadow card-form" }, {
      content: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_HeaderHjpTable, {
              "title-button": "Tambah Jadwal",
              "title-header": "Jadwal Kondektur",
              placeholder: "Cari Disini...",
              onRedirectAdd: _ctx.onAdd,
              onOnSearch: _ctx.onSearch
            }, null, 8, ["onRedirectAdd", "onOnSearch"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_HjpTabView, {
              tabHeader: [
              { title: 'Jadwal Aktif', value: 0 },
              { title: 'Riwayat Jadwal', value: 1 }
            ],
              onTabsChange: _cache[0] || (_cache[0] = (val) => _ctx.activeTab = val)
            }),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.activeTab === 0)
                ? (_openBlock(), _createBlock(_component_ActiveSchedule, {
                    key: 0,
                    searchValue: _ctx.searchValue
                  }, null, 8, ["searchValue"]))
                : _createCommentVNode("", true),
              (_ctx.activeTab === 1)
                ? (_openBlock(), _createBlock(_component_HistorySchedule, {
                    key: 1,
                    searchValue: _ctx.searchValue
                  }, null, 8, ["searchValue"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_OverlayPanel, { ref: "op" }, null, 512)
  ]))
}