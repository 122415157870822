
import { PropType, defineComponent, toRefs, ref } from 'vue'
import moment from 'moment'
import { IListResponseSchedule } from '@/utils/interfaces/iDepartureSchedule'
import Card from 'primevue/card'

export default defineComponent({
  name: 'ListSchedule',
  components: {
    Card
  },
  props: {
    dataItem: {
      type: Object as PropType<IListResponseSchedule>,
      default: null
    },
    module: {
      type: String,
      default: null
    }
  },
  emits: ['showDropDown'],
  setup(props, { emit }) {
    const item: any = ref(props.dataItem)
    const showDropDown = (evt: any, data: IListResponseSchedule) => {
      emit('showDropDown', evt, data)
    }
    return {
      moment,
      item,
      showDropDown
    }
  }
})
