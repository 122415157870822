
import { defineComponent, ref, reactive, PropType, toRefs, watch, getCurrentInstance } from 'vue'
import { DatePicker } from 'v-calendar'
import moment from 'moment'
import HjpDataList from '@/views/components/list/HjpDataList.vue'
import OverlayPanel from 'primevue/overlaypanel'
import ListActions from '@/views/components/list/ListActions.vue'
import { useStore } from 'vuex'
import { ISortValue } from '@/utils/interfaces/iDepartureSchedule'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import apiUseCase from '@/usecase/apiUseCase'
import ScheduleForm from './form/ScheduleForm.vue'
import ListSchedule from './components/ListSchedule.vue'
import SortList from './components/SortList.vue'

const endpoint = '/management/v1/MsSchedule'

export default defineComponent({
  name: 'ActiveSchedule',
  components: {
    DatePicker,
    HjpDataList,
    OverlayPanel,
    ListActions,
    ScheduleForm,
    HjpCofirmModal,
    ListSchedule,
    SortList
  },
  props: {
    searchValue: {
      type: String,
      default: ''
    }
  },
  setup(props: any) {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const {
      searchValue
    } = toRefs(props)
    const rangeDate = ref({
      start: moment().toDate(),
      end: moment().add(7, 'day').toDate(),
    })
    const listSort = ref<ISortValue[]>([
      {
        img: require('@/assets/img/icon/sort-asc-blue.svg'),
        title: 'Tanggal Awal Ascending',
        field: 'StartDate',
        sort: 'asc',
        key: 1
      },
      {
        img: require('@/assets/img/icon/sort-desc-grey.svg'),
        title: 'Tanggal Awal Descending',
        field: 'StartDate',
        sort: 'desc',
        key: 2
      }
    ])
    const sortData = ref<ISortValue>(listSort.value[0])
    const modalConfirmation = reactive({
      show: false,
      title: 'Hapus Jadwal?',
      subTitle: 'Menghapus jadwal akan berdampak kepada kondektur dan trayek bus yang sudah diassign, Tetap hapus jadwal?',
      btnSubmitTitle: 'Hapus Jadwal',
    })
    const params = reactive({
      showHeader: false,
      endpoint,
      headerTitle: 'Tambah Jadwal',
      totalRow: 5,
      filters: [['startDate', '=', moment(rangeDate.value.start.toString()).format('YYYY-MM-DD')], ['endDate', '=', moment(rangeDate.value.end.toString()).format('YYYY-MM-DD')], ['sortBy', '=', sortData.value.field], ['orderDesc', '=', sortData.value.sort.toLowerCase().includes('desc')]],
      searchValue: searchValue.value,
      fieldSearch: ['code'],
      confirmDialogDelete: modalConfirmation
    })
    const dataRow = ref<any>(null)
    const op = ref()
    const opSort = ref()
    const showDropDown = (event: any, data: any) => {
      dataRow.value = data
      op.value.toggle(event)
    }
    const onEdit = (event: any) => {
      console.log('dataRow', dataRow.value)
      if (dataRow.value.AlreadyStarted) {
        op.value.toggle(event)
        $toast.add({
          severity: 'error',
          summary: 'Gagal menyimpan perubahan jadwal!',
          detail: 'Tidak dapat mengubah jadwal yang sedang berlangsung',
          group: 'bc',
          closable: false,
          life: 3000
        })
      } else {
        store.dispatch('storeRowDataListDl', dataRow.value)
        store.dispatch('setShowModalFormDl', true)
      }
    }
    const onDelete = (event: any) => {
      if (dataRow.value.AlreadyStarted) {
        op.value.toggle(event)
        $toast.add({
          severity: 'error',
          summary: 'Gagal menghapus jadwal!',
          detail: 'Tidak dapat menghapus jadwal yang sedang berlangsung',
          group: 'bc',
          closable: false,
          life: 3000
        })
      } else {
        modalConfirmation.show = true
      }
    }
    const onCopy = () => {
      const dataClone = {
        ...dataRow.value,
        isCopy: true
      }
      store.dispatch('storeRowDataListDl', dataClone)
      store.dispatch('setShowModalFormDl', true)
    }
    const hideConfirmModal = () => {
      modalConfirmation.show = false
    }
    const reloadData = () => {
      setTimeout(() => {
        store.dispatch('setReloadDataList')
      }, 100)
    }
    const onChangeDate = (val: any) => {
      const findFilter = params.filters.filter((v) => v[0] !== 'startDate' && v[0] !== 'endDate')
      params.filters = [['startDate', '=', moment(val.start.toString()).format('YYYY-MM-DD')], ['endDate', '=', moment(val.end.toString()).format('YYYY-MM-DD')], ...findFilter]
      reloadData()
    }
    const doSubmit = () => {
      store.dispatch('showLoading')
      hideConfirmModal()
      apiUseCase.delete(endpoint, dataRow.value.Id)
        .then(({ error, message }) => {
          if (!error) {
            $toast.add({
              severity: 'success',
              detail: 'Data berhasil dihapus',
              group: 'bc',
              closable: false,
              life: 3000
            })
            // store.dispatch('hideLoading')
            // store.dispatch('setReloadDataList')
          } else {
            $toast.add({
              severity: 'error',
              detail: message,
              group: 'bc',
              closable: false,
              life: 3000
            })
          }
          store.dispatch('hideLoading')
          store.dispatch('setReloadDataList')
        })
        .catch(({ response }) => {
          console.log('response err =>', response)
          store.dispatch('hideLoading')
        })
    }
    const changeSort = (item: ISortValue) => {
      sortData.value = item
      const findFilter = params.filters.filter((v) => v[0] !== 'orderDesc' && v[0] !== 'sortBy')
      params.filters = [...findFilter, ['sortBy', '=', item.field], ['orderDesc', '=', item.sort.toLowerCase().includes('desc')]]
      reloadData()
    }
    watch(searchValue, (newValue: string) => {
      params.searchValue = newValue
      reloadData()
    })
    return {
      rangeDate,
      params,
      dataRow,
      onEdit,
      onDelete,
      op,
      showDropDown,
      moment,
      onCopy,
      onChangeDate,
      modalConfirmation,
      hideConfirmModal,
      doSubmit,
      opSort,
      listSort,
      sortData,
      changeSort
    }
  }
})
