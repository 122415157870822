
import { ISortValue } from '@/utils/interfaces/iDepartureSchedule'
import { PropType, defineComponent, ref } from 'vue'
import InlineSvg from 'vue-inline-svg'
import OverlayPanel from 'primevue/overlaypanel'

export default defineComponent({
  name: 'SortList',
  components: {
    InlineSvg,
    OverlayPanel
  },
  props: {
    defaultSort: {
      required: true,
      type: Object as PropType<ISortValue>
    },
    listSort: {
      type: Array as PropType<ISortValue[]>,
      default: () => []
    }
  },
  emits: ['onSort'],
  setup(props, { emit }) {
    const opSort = ref()
    const showSort = (evt: Event) => {
      opSort.value.toggle(evt)
    }
    const changeSort = (item: ISortValue) => {
      emit('onSort', item)
    }
    return {
      opSort,
      showSort,
      changeSort
    }
  }
})
