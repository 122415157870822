
import { defineComponent, ref } from 'vue'
import Card from 'primevue/card'
import HeaderHjpTable from '@/views/components/header/HeaderHjpTable.vue'
import ActiveSchedule from '@/views/pages/departure-schedule/ActiveSchedule.vue'
import HjpTabView from '@/views/components/tab/HjpTabView.vue'
import { useStore } from 'vuex'
import OverlayPanel from 'primevue/overlaypanel'
import HistorySchedule from './HistorySchedule.vue'

export default defineComponent({
  name: 'DepartureSchedule',
  components: {
    HeaderHjpTable,
    Card,
    ActiveSchedule,
    HjpTabView,
    OverlayPanel,
    HistorySchedule
  },
  setup() {
    const store = useStore()
    const activeTab = ref<number>(0)
    const op = ref()
    const searchValue = ref<string>('')
    const onAdd = () => {
      store.dispatch('setTitleFormSidebarDl', 'Tambah Jadwal')
      store.dispatch('storeRowDataListDl', null)
      store.dispatch('setShowModalFormDl', true)
    }
    const onSearch = (search: string) => {
      searchValue.value = search
    }
    return {
      activeTab,
      onAdd,
      op,
      onSearch,
      searchValue
    }
  }
})
